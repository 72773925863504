import "../../Registeration/ClubOffer.css";

import {
  useLocation,
  useNavigate,
  Navigate,
  Link,
  useParams,
} from "react-router-dom";
import { useRef, useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Storage } from "../../Firebase/firebase";
import { ToastContainer, toast } from "react-toastify";
import { clubOffer } from "../../../_services/user.service";
import {useTranslation} from "react-i18next";

export default function AddbenifitzToClub() {
  const location = useLocation();
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const clubOfferRef = useRef();

  let [disableBtn, setDisableBtn] = useState(false);
  const [isAnyButtonActive, setIsAnyButtonActive] = useState(false);
  const [button1IsActive, setButton1IsActive] = useState(false);
  const [button2IsActive, setButton2IsActive] = useState(false);
  const [button3IsActive, setButton3IsActive] = useState(false);
  const [largeImageURL, setLargeImageURL] = useState("");
  const [smallImageURL, setSmallImageURL] = useState("");
  const [descriptionLength, setDescriptionLength] = useState(false);
  const [longDescriptionLength, setLongDescriptionLength] = useState(false);
  const [priceValue, setPriceValue] = useState(null);
  const [pointsValue, setPointsValue] = useState(null);
  const [possiblePurchase, setPossiblePurchase] = useState(null);
  const [discountValue, setDiscountValue] = useState(null);
  const [benefitArray, setBenefitArray] = useState([]);
  const [benefitType, setBenefitType] = useState("");

  const setAllFalse = () => {
    setButton1IsActive(false);
    setButton2IsActive(false);
    setButton3IsActive(false);
  };

  const smallImageHandler = async (e) => {
    console.log(e.target.files);

    if (e.target.files[0] == null) return;

    var img = new Image();
    img.src = window.URL.createObjectURL(e.target.files[0]);
    img.onload = async function () {
      var width = img.naturalWidth,
        height = img.naturalHeight;
      console.log(width, height);

      if (height > 40) {
        const storageRef = ref(Storage, `/logos/${e.target.files[0].name}`);

        toast.loading("Uploading Image");
        try {
          const uploadTask = await uploadBytes(storageRef, e.target.files[0]);
          toast.dismiss();
          toast.success("Small Image Uploaded");
          setDisableBtn(false);
        } catch (e) {
          toast.dismiss();
          toast.error("Failed");
          setDisableBtn(false);
        }

        getDownloadURL(ref(Storage, `/logo/${e.target.files[0].name}`)).then(
          (url) => {
            console.log(url);
            setSmallImageURL(url);
          }
        );
        e.target.value = "";
      } else {
        toast.error("Image size doesnot match");
      }
    };
  };

  const largeImageHandler = async (e) => {
    console.log(e.target.files);

    if (e.target.files[0] == null) return;

    var img = new Image();
    img.src = window.URL.createObjectURL(e.target.files[0]);
    img.onload = async function () {
      var width = img.naturalWidth,
        height = img.naturalHeight;
      console.log(width, height);

      if (width > 79 && height > 79) {
        const storageRef = ref(Storage, `/logo/${e.target.files[0].name}`);
        toast.loading("Uploading Image");
        try {
          const uploadTask = await uploadBytes(storageRef, e.target.files[0]);
          toast.dismiss();
          toast.success("Big Image Uploaded");
          setDisableBtn(false);
        } catch (e) {
          toast.dismiss();
          toast.error("Failed");
          setDisableBtn(false);
        }

        getDownloadURL(ref(Storage, `/logo/${e.target.files[0].name}`)).then(
          (url) => {
            console.log(url);
            setLargeImageURL(url);
          }
        );
        e.target.value = "";
      } else {
        toast.error("Image size doesnot match");
      }
    };
  };

  const clubOfferSubmitHandler = async (e) => {
    e.preventDefault();

    setIsAnyButtonActive(false);

    if (benefitType === "") {
      toast.error("Please select benefit type.");
      return;
    }

    if (possiblePurchase.value === "choose option") {
      toast.error("Please choose option from Possible Purchase.");
      return;
    }

    // if(largeImageURL === "" || smallImageURL === ""){
    //     toast.error("Please add both Logos");
    //     return
    // }

    let data = {
      price: priceValue,
      points: pointsValue,
      possiblePurchases:
        possiblePurchase === "null"
          ? null
          : possiblePurchase,
      // discountAmount: button1IsActive ? (+priceValue) - (+pointsValue) : clubOfferRef.valueOf().discount.value,
      discountAmount: discountValue,
      description: descriptionLength,
      longDescription: longDescriptionLength,
      smallPicturlUrl: smallImageURL,
      largePicturlUrl: largeImageURL,
      type: benefitType,
      clubId: id,
    };

    try {
      console.log(data);
      let response = await clubOffer(data);
      response = JSON.parse(response);
      console.log(response);

    } catch (err) {
      alert(err);
      console.log(err);
    }
    setBenefitArray((prev) => {
      return [...prev, data];
    });
    toast("Benefit Added");
    clearValues();
    navigate(-1);
  };

  const clearValues = () => {
    setBenefitType("");
    setPriceValue ( "");
    setPointsValue("");
    setPossiblePurchase ("");
    setDiscountValue ("");
    setDescriptionLength ("");
    setLongDescriptionLength ("");
    setSmallImageURL("");
    setLargeImageURL("");
    setAllFalse();
  };

  return (
    <>
      <div className="flex xsm:flex-col-reverse justify-around xsm:gap-[50px] gap-[15px] m-auto xsm:w-[90.06295754026354vw] w-[79.06295754026354vw] pt-[30px] xsm:pb-[50px]">
        <div className="pb-[2.6574074074074074vh] pt-[2vh] xsm:flex-1 sm:flex-1 xsm:min-w-[90vw] xsm:m-auto sm:min-w-[80vw] w-[43.51vw] py-[10px] bg-[#1D262D] rounded-[57px]">
          <form
            onSubmit={clubOfferSubmitHandler}
            className="flex gap-[10px] flex-wrap items-center xsm:justify-center sm:justify-center x gap-x-[4.1666vw] xl:w-[95%] xsm:w-[100%] sm:w-[100%] w-[80%] h-[100%] m-auto"
          >
            <div className="flex justify-between gap-[10px]">
              <button
                type="button"
                onClick={() => {
                  setIsAnyButtonActive(true);
                  setBenefitType("prepaid");
                  setAllFalse();
                  setButton1IsActive(true);
                }}
                className={
                  button1IsActive
                    ? "flex justify-center items-center gap-[5px] w-[10.748vw] h-[16.701851851851852vh] min-h-[30px] max-h-[50.78px] text-[clamp(14px,1.2vw,18px)] text-[#EBBC33] font-bold bg-[#fff] rounded-[57px] min-w-[80px] xsm:min-w-[25vw] sm:min-w-[20vw] xsm:text-[14px]"
                    : "flex justify-center items-center gap-[5px] xsm:text-[14px] min-w-[80px] xsm:min-w-[25vw] sm:min-w-[20vw] w-[10.748vw] h-[16.701851851851852vh] min-h-[30px] max-h-[50.78px] text-[clamp(14px,1.302vw,18px)] text-[#fff] font-bold bg-[#EBBC33] rounded-[57px]"
                }
              >
                כרטיסייה שמשלמים מראש{" "}
                <div data-title="button 1" className="helpDiv">
                  <img
                    className="w-[1.6473958333333334vw] min-w-[20px]"
                    src="/images/black-mark-center.png"
                  />
                </div>
              </button>
              <button
                type="button"
                onClick={() => {
                  setIsAnyButtonActive(true);
                  setBenefitType("punch");
                  setPointsValue(0);
                  setPriceValue(0);
                  setAllFalse();
                  setButton2IsActive(true);
                }}
                className={
                  button2IsActive
                    ? "flex justify-center items-center gap-[5px] w-[10.748vw] h-[16.701851851851852vh] min-h-[30px] max-h-[50.78px] text-[clamp(14px,1.2vw,18px)] text-[#EBBC33] font-bold bg-[#fff] rounded-[57px] min-w-[80px] xsm:min-w-[25vw] sm:min-w-[20vw] xsm:text-[14px]"
                    : "flex justify-center items-center gap-[5px] xsm:text-[14px] min-w-[80px] xsm:min-w-[25vw] sm:min-w-[20vw] w-[10.748vw] h-[16.701851851851852vh] min-h-[30px] max-h-[50.78px] text-[clamp(14px,1.302vw,18px)] text-[#fff] font-bold bg-[#EBBC33] rounded-[57px]"
                }
              >
                כרטיסית שלא משלמים מראש{" "}
                <div data-title="button 2" className="helpDiv">
                  <img
                    className="w-[1.6473958333333334vw] min-w-[20px]"
                    src="/images/black-mark-center.png"
                  />
                </div>
              </button>
              <button
                type="button"
                onClick={() => {
                  setIsAnyButtonActive(true);
                  setBenefitType("free");
                  setPointsValue(0);
                  setPriceValue(0);
                  setAllFalse();
                  setButton3IsActive(true);
                }}
                className={
                  button3IsActive
                    ? "flex justify-center items-center gap-[5px] w-[10.748vw] h-[16.701851851851852vh] min-h-[30px] max-h-[50.78px] text-[clamp(14px,1.2vw,18px)] text-[#EBBC33] font-bold bg-[#fff] rounded-[57px] min-w-[80px] xsm:min-w-[25vw] sm:min-w-[20vw] xsm:text-[14px]"
                    : "flex justify-center items-center gap-[5px] xsm:text-[14px] min-w-[80px] xsm:min-w-[25vw] sm:min-w-[20vw] w-[10.748vw] h-[16.701851851851852vh] min-h-[30px] max-h-[50.78px] text-[clamp(14px,1.302vw,18px)] text-[#fff] font-bold bg-[#EBBC33] rounded-[57px]"
                }
              >
                הטבה חד פעמית{" "}
                <div data-title="button 3" className="helpDiv">
                  <img
                    className="w-[1.6473958333333334vw] min-w-[20px]"
                    src="/images/black-mark-center.png"
                  />
                </div>
              </button>
            </div>
            <div
              style={
                button2IsActive || isAnyButtonActive === false
                  ? { pointerEvents: "none", opacity: "0.5" }
                  : {}
              }
              className="inline-block"
            >
              <label className="flex items-center gap-[10px] text-[clamp(14px,1.2vw,24px)] text-[#FDC11F] font-bold ">
                {button3IsActive ? "מחיר לםני הנחה *" : "מחיר כולל *"}{" "}
                <div
                  data-title={
                    button3IsActive ? "מחיר לפני הנחה" : "מחיר כולל"
                  }
                  className="helpDiv"
                >
                  <img
                    className="cursor-pointer w-[1.6473958333333334vw] min-w-[20px]"
                    src="/images/yellow-mark.png"
                  />
                </div>
              </label>
              <input
                defaultValue={priceValue}
                onChange={(e) => {
                  (button1IsActive || isAnyButtonActive === false) &&
                    setPriceValue(e.target.value);
                }}
                type={"number"}
                min="0"
                name="price"
                placeholder={
                  button3IsActive ? "מחיר לפני הנחה" : "מחיר כולל"
                }
                required={button2IsActive ? false : true}
                className="xsm:min-w-[80vw] sm:min-w-[calc(35vw-2.083vw)]  indent-[1.3vw] w-[14.85vw] min-h-[30px] h-[3.9592592592592593vh] max-h-[42.76px]"
              />
            </div>

            <div
              style={
                isAnyButtonActive === false
                  ? { pointerEvents: "none", opacity: "0.5" }
                  : {}
              }
              className="inline-block"
            >
              <label className="flex items-center gap-[10px] text-[clamp(14px,1.2vw,24px)] text-[#FDC11F] font-bold">
                {button2IsActive
                  ? "כמות ניקובים *"
                  : button3IsActive
                  ? "מחיר אחרי הנחה *"
                  : "כמות כסף בכרטיסיה/ניקובים *"}{" "}
                <div
                  data-title={
                    button2IsActive
                      ? "כמות ניקובים"
                      : button3IsActive
                      ? "מחיר אחרי הנחה"
                      : "כמות כסף בכרטיסיה/ ניקובים"
                  }
                  className="helpDiv"
                >
                  <img
                    className="cursor-pointer w-[1.6473958333333334vw] min-w-[20px]"
                    src="/images/yellow-mark.png"
                  />
                </div>
              </label>
              <input
                defaultValue={pointsValue}
                onChange={(e) => {
                  (button1IsActive || isAnyButtonActive === false) &&
                    setPointsValue(e.target.value);
                }}
                type={"number"}
                name="points"
                placeholder={
                  button2IsActive
                    ? "מחיר כולל"
                    : button3IsActive
                    ? "מחיר אחרי הנחה"
                    : "כמות כסף בכרטיסיה/ ניקובים"
                }
                required
                className="xsm:min-w-[80vw] sm:min-w-[calc(35vw-2.083vw)] indent-[1.3vw] w-[14.85vw] min-h-[30px] h-[3.9592592592592593vh] max-h-[42.76px]"
              />
            </div>

            <div
              style={
                isAnyButtonActive === false
                  ? { pointerEvents: "none", opacity: "0.5" }
                  : {}
              }
            >
              <label className=" flex items-center gap-[10px] text-[clamp(14px,1.2vw,24px)] text-[#FDC11F] font-bold ">
                 כמות רכישות ללקוח *{" "}
                <div data-title="קבע את כמות הרכישות ללקוח " className="helpDiv">
                  <img
                    className="cursor-pointer w-[1.6473958333333334vw] min-w-[20px]"
                    src="/images/yellow-mark.png"
                  />
                </div>
              </label>
              <select
                style={
                  possiblePurchase === null
                    ? { color: "#9ca3af" }
                    : { color: "black" }
                }
                onChange={(e) => setPossiblePurchase(e.target.value)}
                name="possiblePurchase"
                required
                className="xsm:min-w-[80vw] sm:min-w-[calc(35vw-2.083vw)] indent-[1.3vw] rounded-[57px] w-[14.85vw] min-h-[30px] h-[3.9592592592592593vh] max-h-[42.76px]"
              >
                <option disabled selected value={"choose option"}>
                  {t('limitPurchased')} *
                </option>
                <option value={"1"}>פעם אחת</option>
                <option value={"null"}>ללא הגבלה</option>
              </select>
            </div>

            <div
              style={
                button3IsActive || isAnyButtonActive === false
                  ? { pointerEvents: "none", opacity: "0.5" }
                  : {}
              }
            >
              <label className="flex items-center gap-[10px] text-[clamp(14px,1.2vw,24px)] text-[#FDC11F] font-bold ">
                {button2IsActive ? "Gift Amount *" : "סך-הכל הנחה *"}{" "}
                <div
                  data-title={
                    button2IsActive ? "Gift Amount" : "סך-הכל הנחה"
                  }
                  className="helpDiv"
                >
                  <img
                    className="cursor-pointer w-[1.6473958333333334vw] min-w-[20px]"
                    src="/images/yellow-mark.png"
                  />
                </div>
              </label>
              {button1IsActive && (
                <input
                  defaultValue={button1IsActive && pointsValue - priceValue }
                  onChange={(e) => {
                    (button1IsActive || isAnyButtonActive === false) &&
                      setDiscountValue(e.target.value);
                  }}
                  type={"number"}
                  name="discount"
                  required={button3IsActive ? false : true}
                  placeholder={
                    button2IsActive ? "Gift Amount" : "סך-הכל הנחה"
                  }
                  className="xsm:min-w-[80vw] sm:min-w-[calc(35vw-2.083vw)] indent-[1.3vw] w-[14.85vw] min-h-[30px] h-[3.9592592592592593vh] max-h-[42.76px]"
                />
              )}
              {!button1IsActive && (
                <input
                  type={"number"}
                  name="discount"
                  required={button3IsActive ? false : true}
                  placeholder={
                    button2IsActive ? "Gift Amount" : "סך-הכל הנחה"
                  }
                  className="xsm:min-w-[80vw] sm:min-w-[calc(35vw-2.083vw)] indent-[1.3vw] w-[14.85vw] min-h-[30px] h-[16.9592592592592593vh] max-h-[42.76px]"
                />
              )}
            </div>

            <div
              style={
                isAnyButtonActive === false
                  ? { pointerEvents: "none", opacity: "0.5" }
                  : {}
              }
              className="flex flex-col sm:min-w-[88%]"
            >
              <div>
                <label className="flex items-center gap-[10px] text-[clamp(14px,1.2vw,24px)] text-[#FDC11F] font-bold ">
                  {t('offerDescription')}{" "}
                  <div data-title="description" className="helpDiv">
                    <img
                      className="cursor-pointer w-[1.6473958333333334vw] min-w-[20px]"
                      src="/images/yellow-mark.png"
                    />
                  </div>
                </label>
                <textarea
                  placeholder="Description"
                  onChange={(e) => {
                    setDescriptionLength(e.target.value);
                  }}
                  maxLength={30}
                  name="תיאור"
                  rows="3"
                  className="xsm:min-w-[80vw]  sm:min-w-[100%]  w-[29.21vw]  indent-[1.3vw] pt-[10px] min-h-[60px] h-[7.312037037037037vh] max-h-[78.97px]"
                ></textarea>
                <p className="text-white text-[12px] text-right">
                  {descriptionLength?.length || 0}/30
                </p>
              </div>

              <div>
                <label className="flex items-center gap-[10px] text-[clamp(14px,1.2vw,24px)] text-[#FDC11F] font-bold ">
                  {t('longOfferDescription')}{" "}
                  <div data-title="long discription" className="helpDiv">
                    <img
                      className="cursor-pointer w-[1.6473958333333334vw] min-w-[20px]"
                      src="/images/yellow-mark.png"
                    />
                  </div>
                </label>
                <textarea
                  placeholder="Long Description"
                  onChange={(e) => {
                    setLongDescriptionLength(e.target.value);
                  }}
                  maxLength={150}
                  name={t('longOfferDescription')}
                  className="xsm:min-w-[80vw] sm:min-w-[100%] indent-[1.3vw] pt-[10px] w-[29.21vw] min-h-[70px] h-[14.025925925925923vh] max-h-[151.48px]"
                ></textarea>
                <p className="text-white text-[12px] text-right">
                  {longDescriptionLength?.length || 0}/150
                </p>
              </div>
            </div>

            <div
              style={
                isAnyButtonActive === false
                  ? { pointerEvents: "none", opacity: "0.5" }
                  : {}
              }
              className="flex justify-between w-[100%] xsm:items-center xsm:flex-col sm:items-center sm:flex-col"
            >
              <div className="flex gap-[20px]">
                <div className="text-[clamp(14px,1.2vw,24px)] text-[#fff] font-bold">
                  <h2 className="font-[25px] font-bold ">{t('smallLogoPicture')}</h2>
                  <div className=" flex justify-center items-center w-[119px] h-[8.24074074074074vh] min-h-[50px] max-h-[89px] bg-white rounded-[18px]">
                    <input
                      defaultValue={smallImageURL}
                      onChange={(e) => {
                        setDisableBtn(true);
                        smallImageHandler(e);
                      }}
                      name="img1"
                      id="files1"
                      accept="image/*"
                      type={"file"}
                      className="hidden w-[301px] min-h-[30px] h-[3.9592592592592593vh] max-h-[42.76px]"
                    />
                    <label
                      className="cursor-pointer text-[14px] text-center w-[62px] h-[24px] bg-[#FDC11F] border-[1px] border-[#707070]"
                      for="files1"
                    >
                      Upload
                    </label>
                  </div>
                  <h2 className="font-[15px] font-bold text-center">
                    min 141*80{" "}
                  </h2>
                </div>

                <div className="text-[clamp(14px,1.2vw,24px)] text-[#fff] font-bold">
                  <h2 className="font-[25px] font-bold ">תמונת לוגו גדולה</h2>
                  <div className=" flex justify-center items-center w-[129px] h-[8.24074074074074vh] min-h-[50px] max-h-[89px] bg-white rounded-[18px]">
                    <input
                      defaultValue={largeImageURL}
                      onChange={(e) => {
                        setDisableBtn(true);
                        largeImageHandler(e);
                      }}
                      name="img2"
                      id="files2"
                      accept="image/*"
                      type={"file"}
                      className="hidden w-[301px] min-h-[30px] h-[3.9592592592592593vh] max-h-[42.76px]"
                    />
                    <label
                      className="cursor-pointer text-[14px] text-center w-[62px] h-[24px] bg-[#FDC11F] border-[1px] border-[#707070]"
                      for="files2"
                    >
                      Upload
                    </label>
                  </div>
                  <h2 className="font-[15px] font-bold text-center">
                    min 450*200{" "}
                  </h2>
                </div>
              </div>

              {/* <div
                  style={
                    disableBtn ? { pointerEvents: "none", opacity: "0.5" } : {}
                  }
                  className="xsm:mt-[15px] sm:mt-[15px] flex flex-col xsm:items-center sm:items-center gap-[10px]"
                >
                  <button
                    type="submit"
                    className="w-[140px] h-[34px] bg-[#FDC11F] border-[1px] border-[#707070]"
                  >
                    Add Benefits
                  </button>
                  <img
                    className="cursor-pointer"
                    src="/images/Group 771.png"
                    alt=""
                  />
                </div> */}

              {/* Mobile next btn */}
            </div>

            <button
              className="bg-[#FDC11F] text-[#1D262D] font-bold self-center"
              type="submit"
            >
              יצירת ההטבה
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
